/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
import React, { useState, useEffect } from "react";
import { Upload, message, Image, notification } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const ellipsis = (text, size) => {
  return `${text.split(" ").slice(0, size).join(" ")}...`;
};

// Get User Data From Local Storage
const userData = () => {
  const userData = JSON.parse(localStorage.getItem("user_data")) || {};
  return userData;
};

const idGenerator = (events, length = 1) => {
  const arrayData = [];
  events.map((data) => {
    return arrayData.push(parseInt(data.id, 10));
  });
  const number = (Math.max(...arrayData) + 1).toString();
  return number.length < length
    ? `${"0".repeat(length - number.length)}${number}`
    : number;
};

const ImageUploader = ({ imageUrl, handleChange, form }) => {
  const [loadingImg, setLoadingImg] = useState(false);

  // Image Uploader
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result, img));
    reader.readAsDataURL(img);
  };

  // Before Image Upload
  const beforeUpload = (file) => {
    if (file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    }
  };

  // Handle Image Change
  const handleImageChange = (info) => {
    if (info.file.status === "uploading") {
      setLoadingImg(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info?.file?.originFileObj, (url, imageObj) => {
        setLoadingImg(false);
        handleChange(url, imageObj);
      });
    }
    if (info.file.status === "error") {
      setLoadingImg(false);
      handleChange(null, null);
      form.setFields([
        { name: "image", value: null },
        { name: "image", errors: ["Image is not compatible."] },
      ]);
    }
  };

  const uploadButton = (
    <div>
      {loadingImg ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}> Upload </div>
    </div>
  );

  return (
    <Upload
      name="image"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      multiple={false}
      maxCount={1}
      // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
      action="https://run.mocky.io/v3/3eaa9b5f-bf0a-4454-ac52-2a3e051e1a20"
      beforeUpload={beforeUpload}
      onChange={handleImageChange}
    >
      {imageUrl ? (
        <img src={imageUrl} alt="image" style={{ width: "100%" }} />
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

// Image Upload and Remove - Based on (In-Page-Api)
const ImageUpload = ({form, editable, imageUrl, handleChange, multiImage, disabled, deletImg, baseUrl }, ...props ) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if(editable){
      if (imageUrl && imageUrl?.length > 0) {
        const checkArrary = Array.isArray(imageUrl) ? imageUrl : [imageUrl];
        const fileListData = checkArrary?.map((url, index) => ({
          uid: index,
          name: `image-${index + 1}`,
          status: "done",
          url: baseUrl + url,
        }));
        setFileList(fileListData);
        validateImages(fileListData);
      } else {
        setFileList([])
      }
    }
  }, [imageUrl]);

  const onChange = ({ fileList }) => {
    // Check if the file size is less than 5 MB (5 MB)
    const list = fileList?.filter((data) => {
      const isLt5M = data.size / 1024 / 1024 < 5;
      if (!isLt5M && data.size) {
        message.error("Image must be smaller than 5MB!");
        return
      }
      return !data?.size || data?.size / 1024 / 1024 < 5;
    });
    setFileList(list);
    validateImages(list);
  };

  const onRemove = (file) => {
    const imgName = file?.url?.replace(baseUrl, ""); // Get the image name from the URL and pass it to the deletImg function
    if (imgName && deletImg) {
      const type = multiImage ? "product" : "cover" ;
      deletImg(type, imgName);
    }
    const updatedFileList = fileList?.filter((item) => item?.uid !== file?.uid);    // Filter out the removed file from the fileList state
    setFileList(updatedFileList);
  };

  const validateImages = (fileObj)=>{ //Validate Image validation
    const originFileObjs =  fileObj
    ?.filter((data) => data?.originFileObj) 
    ?.map((data) => data?.originFileObj)

    if (handleChange) {
      handleChange(originFileObjs, fileObj);
    }
  }

  const uploadButton = (
    <div>
       <PlusOutlined />
      <div style={{ marginTop: 8 }}> Upload </div>
    </div>
  );

  return (
    <Upload
      name="image"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={true}
      multiple={multiImage}
      maxCount={multiImage ? 5 : 1}
      accept=".jpg, .jpeg, .png"
      onChange={onChange}
      fileList={fileList}
      onRemove={onRemove}
      beforeUpload={() => false} // Disable automatic upload  || disiseble action api hit
      disabled={disabled}
    >
      { multiImage ?
        fileList?.length < 5 && (uploadButton)
        :
        fileList?.length < 1 && (uploadButton)
      }
    </Upload>
  );
};

// Single Image Uploader - Based on (Out-Page-Api)
const SingleImageUploader = ({ imageUrl, handleChange, form, page, editable, setImg, baseURL }) => {
  const data = localStorage.getItem("token") || "";
  const multiImage = page == "addPainting" ? true : false;
  const domain = process.env.REACT_APP_BASEURL + "/";
  const [fileList, setFileList] = useState([]);

  const fileUploadPropss = {
    action: process.env.REACT_APP_BASEURL + "/user/profile/img",
    name: "file",
    method: "POST",
    headers: {
      Authorization: `Bearer ${data}`,
      ContentType: "multipart/form-data",
    },
    beforeUpload: (file) => {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5; // Checking file size is less than 5MB
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
        return false;
      }
      if (!isLt5M) {
        message.error("Image must be smaller than 5MB!");
        return false;
      }
      return true;
    },
  };

  useEffect(() => {
    if (editable) {
      if (imageUrl && imageUrl?.length > 0) {
        const checkArrary = Array.isArray(imageUrl) ? imageUrl : [imageUrl];
        const fileListData = checkArrary?.map((url, index) => ({
          uid: index,
          name: `image-${index + 1}`,
          status: "done",
          url: baseURL + "/" + url,
        }));
        setFileList(fileListData);
      }
    }
  }, [imageUrl]);

  const onChange = ({ fileList }) => {
    // Check if the file size is less than 5 MB (5 MB)
    const list = fileList?.filter((data) => {
      return !data?.size || data?.size / 1024 / 1024 < 5;
    });
    setFileList(list);
    // Check if the status is done and urls are present
    const urls = fileList?.map((data) => {
      if (data.status == "done") {
        return data?.response || data?.url?.substring(domain?.length);
      }
    });
    // Handle Image Upload For Edit Unlisted Paintings
    if (handleChange) handleChange(urls);
    if (setImg) setImg(urls);
  };

  const onRemove = (e)=>{
    form.setFieldsValue({ image: null });
    form.validateFields(["image"]);
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}> Upload </div>
    </div>
  );

  return (
    <div>
      <Upload
        {...fileUploadPropss}
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        showUploadList={true}
        multiple={multiImage ? true : false}
        name="image"
        accept=".jpg, .jpeg, .png"
        maxCount={multiImage ? 10 : 1}
        onRemove={e=>onRemove(e)}
      >
        {multiImage
          ? fileList.length < 10 && uploadButton
          : fileList.length < 1 && uploadButton}
      </Upload>
    </div>
  );
};


// Get Remember Email for LogIn
const remEmail = (form, setRemMe) => {
  const remMe = localStorage.getItem("rememberedEmail");
  if (remMe) {
    form.setFieldsValue({
      email: remMe,
    });
    setRemMe(true);
  }
};

// Clear Local Storage
const clearLocalStorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("user_data");
  localStorage.removeItem("logedIn");
};

// Antd Password and Confirm Password Validator
const validatePassword = (_, value, form) => {
  const { getFieldValue } = form;
  if (!value) {
    return Promise.reject("You cannot leave this field blank");
  } else if (value && value !== getFieldValue("new_password")) {
    return Promise.reject(
      "New password and confirm new password does not match"
    );
  }
  return Promise.resolve();
};


// Image Group Preview
const ImageGroup = ({ images, baseURL, type }) => {
  return (
    <div className="image-group-preview">
      <Image.PreviewGroup>
        {Array.isArray(images) ? (
          images?.map((image, index) => (
            <Image
              key={index}
              width={80}
              src={baseURL + (type === "TaskMonitoringDetails" ? image?.img : image)}
            />
          ))
        ) : (
          <Image src={baseURL + images} />
        )}
      </Image.PreviewGroup>
    </div>
  );
};


export {
  ellipsis,
  idGenerator,
  ImageUpload,
  ImageUploader,
  remEmail,
  userData,
  clearLocalStorage,
  validatePassword,
  SingleImageUploader,
  ImageGroup
};
