import Cookies from "js-cookie";
import actions from "./actions";
import { notification } from "antd";
import { DataService } from "../../config/dataService/dataService";
import { loginUser, logoutUser, getProfile } from "../../utility/api";
import { clearLocalStorage } from "../../utility/utility";

const {
  loginBegin,
  loginSuccess,
  loginErr,
  logoutBegin,
  logoutSuccess,
  logoutErr,
  loginUserData,
} = actions;

const login = (values, remMe, navigate) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const result = await loginUser(values);
      const { data, status, error } = result || {};
      const { access_token, refresh_token, iev } = data || {};
      if (status === 201) {
        localStorage.setItem("token", access_token);
        localStorage.setItem("refresh_token", refresh_token);
        const userDataApi = await getProfile();
        if (userDataApi?.status === 200) {
          localStorage.setItem("user_data", JSON.stringify(userDataApi?.data));
          localStorage.setItem("logedIn", true);
          userDataAction(userDataApi?.data);
          dispatch(loginSuccess(true));
          navigate("/admin/dashboard");
          if (remMe) {
            localStorage.setItem("rememberedEmail", values?.email);
          } else {
            localStorage.removeItem("rememberedEmail");
          }
          notification.success({ message: "Login Successful" });
        } else {
          notification.error({ message: "Internal server error" });
          dispatch(loginErr("Internal server error"));
        }
      } else {
        dispatch(loginErr("Error"));
        notification.error({ message: error?.message || error });
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const fbLogin = (callback) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      // Cookies.set("logedIn", true);
      localStorage.setItem("logedIn", true);
      dispatch(loginSuccess(true));
      callback();
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const register = (values) => {
  return async (dispatch) => {
    dispatch(loginBegin());
    try {
      const response = await DataService.post("/register", values);
      if (response.data.errors) {
        dispatch(loginErr("Registration failed!"));
      } else {
        dispatch(loginSuccess(false));
      }
    } catch (err) {
      dispatch(loginErr(err));
    }
  };
};

const logOut = (callback) => {
  const refreshToken = localStorage.getItem("refresh_token");
  return async (dispatch) => {
    dispatch(logoutBegin());
    try {
      await logoutUser({ refresh_token: refreshToken })
        .then((res) => {
          if (res.status === 200) {
            clearLocalStorage();
            // Cookies.remove("logedIn");
            // Cookies.remove("access_token");
            dispatch(logoutSuccess(false));
            notification.success({ message: res?.data?.message });
            callback();
          } else if (res.message === "No authorization token was found") {
            dispatch(logoutSuccess(false));
            clearLocalStorage();
          } else {
            console.log("Error");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      dispatch(logoutErr(err));
    }
  };
};

const userDataAction = (data) => {
  return async (dispatch) => {
    dispatch(loginUserData(data));
  };
};

export { login, logOut, register, fbLogin, userDataAction };
