import { Spin } from 'antd';
import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Routes, useLocation, Navigate } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';
// import { TermsConditions } from '../../HandyMan/CMS/TermsConditions/TermsConditions';

// Dashboard
const Dashboard = lazy(() => import('../../HandyMan/Dashboard/Dashboard'));

// Profile
const Profile = lazy(() => import('../../HandyMan/Profile/EditProfile'))
const ChangePassword = lazy(() => import('../../HandyMan/Profile/ChangePassword'))

// Categories
const Categories = lazy(() => import('../../HandyMan/Categories/Categories'));

// Services
const Services = lazy(() => import('../../HandyMan/Service/Services'));

// Task Monitoring
const TaskMonitoring = lazy(() => import('../../HandyMan/TaskMonitoring/TaskMonitoring'));
const TaskMonitoringDetails = lazy(() => import('../../HandyMan/TaskMonitoring/TaskMonitoringDetails'));

// Reviews
const LandlordReviews = lazy(() => import('../../HandyMan/Reviews/Landlords/LandlordReviews'));
const TaskersReviews = lazy(() => import('../../HandyMan/Reviews/Taskers/TaskersReviews'));
const ReviewsDetails = lazy(() => import('../../HandyMan/Reviews/ReviewsDetails'));

// Payouts
const PendingPayouts = lazy(() => import('../../HandyMan/Payouts/PendingPayouts/PendingPayouts'));
const PaidPayouts = lazy(() => import('../../HandyMan/Payouts/PaidPayouts/PaidPayouts'));

// Users
const RegisteredTaskers = lazy(() => import('../../HandyMan/Users/RegisteredTaskers/RegisteredTaskers'));
const RegisteredTaskersDetails = lazy(() => import('../../HandyMan/Users/RegisteredTaskers/RegisteredTaskersDetails'));
const RegisteredLandlord = lazy(() => import('../../HandyMan/Users/RegisteredLandlord/RegisteredLandlord'));
const RegisteredLandlordDetails = lazy(() => import('../../HandyMan/Users/RegisteredLandlord/RegisteredLandlordDetails'));

//CMS
const BannerManagement = lazy(() => import('../../HandyMan/CMS/BannerManagement/BannerManagement'))
const TermsConditions = lazy(() => import('../../HandyMan/CMS/TermsConditions/TermsConditions'))
const CreateTermsConditions = lazy(() => import('../../HandyMan/CMS/TermsConditions/CreateTermsConditions'))
const PrivacyPolicy = lazy(() => import('../../HandyMan/CMS/PrivacyPolicy/PrivacyPolicy'))
const FAQs = lazy(() => import('../../HandyMan/CMS/FAQs/FAQs'))
const FAQsTaskers = lazy(() => import('../../HandyMan/CMS/FAQs/FAQSTaskers'))
const RefundPolicy = lazy(() => import('../../HandyMan/CMS/RefundPolicy/RefundPolicy'))
const AboutUs = lazy(() => import('../../HandyMan/CMS/AboutUs/AboutUs'))

// Notifications
const Notifications = lazy(() => import('../../HandyMan/Notifications/Notifications'));

// Booking Disputes
const BookingDisputes = lazy(()=> import('../../HandyMan/BookingDisputes/BookingDisputes'));
const BookingDisputesDetails = lazy(()=> import('../../HandyMan/BookingDisputes/BookingDisputesDetails'));

//  Disputes
const Disputes = lazy(()=> import('../../HandyMan/CMS/Disputes/Disputes'));
const DisputesDetails = lazy(()=> import('../../HandyMan/CMS/Disputes/DisputesDetails'));

//  Cancellation
const Cancellation = lazy(()=> import('../../HandyMan/CMS/Cancellation/Cancellation'));
const CancellationDetails = lazy(()=> import('../../HandyMan/CMS/Cancellation/CancellationDetails'));

// Settings
const VatSettings = lazy(() => import('../../HandyMan/Settings/VatSettings'));
const CommissionSettings = lazy(() => import('../../HandyMan/Settings/CommissionSettings'));
const CancellationSettings = lazy(() => import('../../HandyMan/Settings/CancellationSettings'));
const SiteSettings = lazy(() => import('../../HandyMan/Settings/SiteSettings'));


// Not Found
// const NotFound = lazy(() => import('../../pages/404'));

const Admin = React.memo(() => {
  
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Routes>

      {/*Dashboard */}
      <Route path="admin/dashboard" element={<Dashboard />} />

      {/* Profile */}
      <Route path="admin/edit-profile" element={<Profile />}  />
      <Route path="admin/change-password" element={<ChangePassword />}  />

       {/* Categories */}
       <Route path="admin/categories" element={<Categories />} />

       {/* Services */}
       <Route path="admin/services" element={<Services />} />

       {/*Task Monitoring*/}
       <Route path="admin/task-monitoring" element={< TaskMonitoring />} />
       <Route path="admin/task-monitoring/:id" element={< TaskMonitoringDetails />} />

        {/* Reviews */}
        <Route path="admin/reviews/landlord" element={<LandlordReviews />} />
        <Route path="admin/reviews/taskers" element={<TaskersReviews />} />
        <Route path="admin/reviews/tasker-details/:id" element={<ReviewsDetails />} /> 
        <Route path="admin/reviews/landlord-details/:id" element={<ReviewsDetails />} /> 

        {/* Payouts */}
        <Route path="admin/payout/pending-payouts" element={<PendingPayouts />} />
        <Route path="admin/payout/paid-payouts" element={<PaidPayouts />} />

        {/* Booking Disputes */}
        <Route path="admin/booking-disputes" element={<BookingDisputes />} />
        <Route path="admin/booking-disputes/:id" element={<BookingDisputesDetails />} />
      
        {/* Users */}
        <Route path="admin/users/registered-taskers" element={<RegisteredTaskers />} />
        <Route path="admin/users/registered-taskers-details/:id" element={<RegisteredTaskersDetails />} />
        <Route path="admin/users/registered-landlord" element={<RegisteredLandlord />} />
        <Route path="admin/users/registered-landlord-details/:id" element={<RegisteredLandlordDetails />} />

        {/* CMS */}
        <Route path="admin/cms/about-us" element={<AboutUs />} />

        <Route path="admin/cms/banner-management" element={<BannerManagement />} />
        <Route path="admin/cms/banner-management/create" element={<CreateTermsConditions />} />
        <Route path="admin/cms/terms-conditions" element={<TermsConditions />} />
        <Route path="admin/cms/terms-conditions/create" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/privacy-policy/create" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/faqs/landlords/create" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/faqs/taskers/create" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/refund-policy/create" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/disputes/create" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/cancellation/create" element={<CreateTermsConditions />} />

        <Route path="admin/cms/banner-management/edit" element={<CreateTermsConditions />} />
        <Route path="admin/cms/terms-conditions/edit" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/privacy-policy/edit" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/faqs/taskers/edit" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/faqs/landlords/edit" element={<CreateTermsConditions />}  />
        <Route path="admin/cms/refund-policy/edit" element={<CreateTermsConditions />}  />

        <Route path="admin/cms/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="admin/cms/refund-policy" element={<RefundPolicy />} />
        <Route path="admin/cms/faqs/landlords" element={<FAQs />} />
        <Route path="admin/cms/faqs/taskers" element={<FAQsTaskers />} />
        
        <Route path="admin/cms/disputes" element={<Disputes />} />
        {/* <Route path="admin/cms/disputes/:id" element={<DisputesDetails />} /> */}
        <Route path="admin/cms/disputes/edit" element={<CreateTermsConditions />} />
        <Route path="admin/cms/cancellation" element={<Cancellation />} />
        <Route path="admin/cms/cancellation/edit" element={<CreateTermsConditions />} />

        {/* Notifications */}
        <Route path="admin/notifications" element={<Notifications />} />
        
        {/* Settings */}
        <Route path="admin/settings/vat" element={<VatSettings />} />
        <Route path="admin/settings/commission" element={<CommissionSettings />} />
        <Route path="admin/settings/cancellation" element={<CancellationSettings />} />
        <Route path="admin/settings/site-settings" element={<SiteSettings />} />
      
       {/* Not Found */}
       {/* <Route path="*" element={<NotFound />} /> */}
       <Route path="*" element={<Navigate to="/admin/dashboard" />} />
      </Routes>
    </Suspense>
  );
});

export default withAdminLayout(Admin);