import { apiHeader } from "./BaseUrl";


// Auth - Login
export const loginUser = async (values) => {
  let data;
  await apiHeader
    .post("/auth/token", {
      email: values.email,
      password: values.password,
      device_token: "string",
      device_type: "web",

    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Auth - Logout
export const logoutUser = async () => {
  const access_token = localStorage.getItem('token') || {};
  let data;
  await apiHeader
    .post("/auth/logout", {
      access_token: access_token,
      device_token: "string"
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Auth - Forgot Password
export const forgotPassword = async (values) => {
  let data;
  await apiHeader
    .post("/auth/forgotpwd", {
      email: values.email,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


//Auth Refresh Token
export const refreshAccessToken = async (refreshToken) => {
  let data;
  await apiHeader
    .post("/auth/token/refresh", {
      refresh_token: refreshToken,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Auth - Reset Password Api
export const resetPassword = async (values, token) => {
  let data;
  await apiHeader
    .post("/auth/forgotpwd/reset", {
      email: values.email,
      forgot_pwd_token: token,
      new_password: values.new_password,
      confirm_new_password: values.confirm_password,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Use to Verify Email for (Mobile App Front Only)
export const emailVerify = async (values, token) => {
  let data;
  await apiHeader
    .post("/auth/email/verify", {
      email: values.email,
      token: token,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Profile - User Profile Get Data 
export const getProfile = async (values) => {
  let data;
  await apiHeader
    .get("/user/profile")
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Profile - User Profile Change Password
export const changePassword = async (values) => {
  let data;
  await apiHeader
    .put("/auth/password", {
      email: values.email,
      old_password: values.old_password,
      new_password: values.new_password,
      confirm_password: values.confirm_password,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Profile - User Profile Edit Data
export const editProfile = async (values) => {
  let data;
  await apiHeader
    .put("/user/profile", {
      first_name: values.first_name,
      last_name: values.last_name,
      slug: values.phone.slug,
      country_code: values.phone.code,
      number: values.phone.number,
      profile_picture: values.image
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
    return data;
};


//CMS
export const cmsGet = async (limit, offset, search,type) => {
  let data;
  const queryParams = search ? `?search=${search}&limit=${limit}&offset=${offset}` : `?limit=${limit}&offset=${offset}`;
     await apiHeader
    .get(`/${type}/${queryParams}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const cmsCreate = async (type,values) => {
  let data;
  await apiHeader
    .post(`/${type}`, {
      title_en: values.title,
      title_ar: values.titlearabic,
      description_en: values.description,
      description_ar: values.descriptionarabic
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const cmsUpdate = async (type,values,id) => {
  let data;
  await apiHeader
    .put(`/${type}/${id}`,{
      title_en: values.title,
      title_ar: values.titlearabic,
      description_en: values.description,
      description_ar: values.descriptionarabic
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const cmsDelete = async (type,id) => {
  let data;
  await apiHeader
    .delete(`/${type}/${id}`).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const cmsStatusUpdate = async (type,id, statusActive) => {
  let data;
  await apiHeader
   .patch(`/${type}/${id}/status`, {
      status: statusActive,
    })
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const cmsSwap = async (type,new_value, new_id) => {
  let data;
  await apiHeader
    .patch(`/${type}/sort-order`, {
      new_value: new_value,
      new_id: new_id,
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const aboutUs = async () => {
  let data;
  await apiHeader
    .get("/about-us")
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const faqGet = async (limit, offset, search,type) => {
  let data;
  const queryParams = search ? `?search=${search}&limit=${limit}&offset=${offset}&type=${type}` : `?limit=${limit}&offset=${offset}&type=${type}`;
  await apiHeader
    .get(`/faq${queryParams}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const faqCreate = async (type,values) => {
  let data;
  await apiHeader
    .post(`/faq`, {
      type:type,
      title_en: values.title,
      title_ar: values.titlearabic,
      description_en: values.description,
      description_ar: values.descriptionarabic
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const faqSwap = async (type,new_value, new_id) => {
  let data;
  await apiHeader
    .patch(`/faq/sort-order`, {
      new_value: new_value,
      new_id: new_id,
      type:type
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const faqUpdate = async (type,values,id) => {
  let data;
  await apiHeader
    .put(`/faq/${id}`,{
      type:type,
      title_en: values.title,
      title_ar: values.titlearabic,
      description_en: values.description,
      description_ar: values.descriptionarabic
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Dashboard Card States
export const dashboardStates = async () => {
  let data;
  await apiHeader
    .get("/dashboard")
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Categories
export const categories = async (limit, offset, search) => {
  let data;
  let queryParam = `?limit=${limit}&offset=${offset}`
  if(search) queryParam += `&search=${search}` 
  await apiHeader
    .get(`/categories/${queryParam}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const categoriesStatusUpdate = async (id, active) => {
  let data;
  await apiHeader
    .patch(`/categories/${id}/status`, {active: !active})
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


//Landlord Status Update
export const landlordStatusUpdate = async (id, active) => {
  let data;
  await apiHeader
    .patch(`/landlords/${id}/status`, {active: !active})
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// View Landlords Details
export const landlordDetails = async (id) => {
  let data;
  await apiHeader
    .get(`/landlords/${id}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// View Landlords Reviews
export const landlordReviews = async (id, limit, offset) => {
  let data;
  await apiHeader
    .get(`/landlords/${id}/reviews?&limit=${limit}&offset=${offset}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// View Taskers Listing
export const taskers = async (limit, offset, search) => {
  let data;
  let queryParam = `?limit=${limit}&offset=${offset}`
  if(search) queryParam += `&search=${search}`
  await apiHeader
    .get(`/taskers/${queryParam}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Taskers Status Update
export const taskerStatusUpdate = async (id, active) => {
  let data;
  await apiHeader
    .patch(`/taskers/${id}/status`, {active: !active})
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// View Taskers Details
export const taskerDetails = async (id) => {
  let data;
  await apiHeader
    .get(`/taskers/${id}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Tasker Account Status Update
export const taskerAccountUpdate = async (values, id) => {
  let data;
  await apiHeader
   .patch(`/taskers/${id}/account/status`, {
    iav: values.iav,
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

// View Taskers Reviews
export const taskersReviews = async (id, limit, offset) => {
  let data;
  await apiHeader
    .get(`/taskers/${id}/reviews?&limit=${limit}&offset=${offset}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

export const categoriesCreate = async (values) => {
  let data;
  await apiHeader
    .post(`/categories`, values)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const categoriesImgUpload = async (id, img) => {
  let data;

  const formData = new FormData();
  formData.append("image", img);

  await apiHeader
    .post(`/categories/${id}/img`, formData)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const categoriesUpdate = async (id, values) => {
  let data;
  await apiHeader
    .put(`/categories/${id}`, values)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Services
export const services = async (limit, offset, search) => {
  let data;
  let queryParam = `?limit=${limit}&offset=${offset}`
  if(search) queryParam += `&search=${search}` 
  await apiHeader
    .get(`/services/${queryParam}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const servicesAllCategories = async () => {
  let data;
  await apiHeader
    .get(`/services/categories`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const servicesStatusUpdate = async (id, active) => {
  let data;
  await apiHeader
    .patch(`/services/${id}/status`, {active: !active})
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// View Landlords Listing
export const landlord = async (limit, offset, search) => {
  let data;
  let queryParam = `?limit=${limit}&offset=${offset}`
  if(search) queryParam += `&search=${search}`
  await apiHeader
    .get(`/landlords/${queryParam}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const servicesCreate = async (values) => {
  let data;
  await apiHeader
    .post(`/services`, values)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const servicesImgUpload = async (id, img) => {
  let data;

  const formData = new FormData();
  formData.append("image", img);

  await apiHeader
    .post(`/services/${id}/img`, formData)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const servicesUpdate = async (id, values) => {
  let data;
  await apiHeader
    .put(`/services/${id}`, values)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Reviews
export const reviews = async (limit, offset, type, tab, search) => {
  let data;
  let queryParam = `?limit=${limit}&offset=${offset}&type=${type}&tab=${tab}`
  if(search) queryParam += `&search=${search}` 
  await apiHeader
    .get(`/reviews/${queryParam}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const reviewById = async (id) => {
  let data;
  await apiHeader
    .get(`/reviews/${id}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const reviewsStatusUpdate = async (id, status) => {
  let data;
  await apiHeader
    .patch(`/reviews/${id}/status`, {status: status})
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


//Settings
export const settings = async (type) => {
  let data;
  await apiHeader
    .get(`setting/${type}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


//Settings Update
export const settingsUpdate = async (type, values) => {
  let data;
  await apiHeader
    .put(`/setting/${type}`, 
      { 
        commission: values.commission,
        landlord_cancellation: values.cancellationLandlord,
        tasker_cancellation: values.cancellationTasker,
        vat_amount: values.vat,
        contact_information:{
          address:{en: values.address_en, ar: values.address_ar},
          phone: values.phone,
          email: values.email,
        },
        social_media_links: {
          facebook: values.facebook,
          instagram: values.instagram,
          tiktok: values.tiktok,
          youtube: values.youtube,
        },
        app_links: {
          google_play:values.googlelink,
          app_store: values.appstorelink
        },
        
      })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Payouts
export const payouts = async (limit, offset, type, search) => {
  let data;
  let queryParam = `?limit=${limit}&offset=${offset}&type=${type}`
  if(search) queryParam += `&search=${search}` 
  await apiHeader
    .get(`/payouts/${queryParam}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const payoutsStatusUpdate = async (id, transactionId) => {
  let data;
  await apiHeader
    .patch(`/payouts/${id}/update`, {transaction_id: transactionId})
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Task Monitoring - Listing
export const taskMonitoring = async (limit, offset, search) => {
  let data;
  let queryParam = `?limit=${limit}&offset=${offset}`
  if(search) queryParam += `&search=${search}` 
  await apiHeader
    .get(`/tasks/${queryParam}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Task Monitoring - Details
export const taskMonitoringDetails = async (id) => {
  let data;
  await apiHeader
    .get(`/tasks/${id}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Booking Disputes - Listing
export const bookingDisputes = async (limit, offset, search) => {
  let data;
  let queryParam = `?limit=${limit}&offset=${offset}`
  if(search) queryParam += `&search=${search}` 
  await apiHeader
    .get(`/booking_disputes/${queryParam}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Booking Disputes - Status Update
export const bookingDisputesStatus = async (id) => {
  let data;
  await apiHeader
    .patch(`/booking_disputes/${id}/status`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Booking Disputes - Details
export const bookingDisputesDetails = async (id) => {
  let data;
  await apiHeader
    .get(`/booking_disputes/${id}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// CMS - Cancellations  
export const cancellations = async (limit, offset, search) => {
  let data;
  let queryParam = `?limit=${limit}&offset=${offset}`
  if(search) queryParam += `&search=${search}` 
  await apiHeader
    .get(`/cancellations/${queryParam}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// CMS - Cancellations - Status Update
export const cancellationsStatus = async (id, status) => {
  let data;
  await apiHeader
    .patch(`/cancellations/${id}/status`,{
      status: status
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// CMS - Disputes
export const disputes = async (limit, offset, search) => {
  let data;
  let queryParam = `?limit=${limit}&offset=${offset}`
  if(search) queryParam += `&search=${search}` 
  await apiHeader
    .get(`/disputes${queryParam}`)
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// CMS - Dispute Create
export const disputeCreate = async (type,values) => {
  let data;
  await apiHeader
    .post(`/${type}`, {
      title_en: values.title,
      title_ar: values.titlearabic,
      type: values.type
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// CMS - Disputes - Status Update
export const disputesStatus = async (id, status) => {
  let data;
  await apiHeader
    .patch(`/disputes/${id}/status`,{
      status: !status
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const disputUpdate = async (type,values,id) => {
  let data;
  await apiHeader
    .put(`/${type}/${id}`,{
      title_en: values.title,
      title_ar: values.titlearabic,
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// Notifications
export const notifications = async (values) => {
  let data;
  await apiHeader
    .post(`/notification`, {
      user_type: values.users,
      subject: values.subject,
      subject_ar: values.subject_ar,
      message: values.message,
      message_ar: values.message_ar
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};