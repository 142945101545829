import {
  UilCreateDashboard,
  UilFileShieldAlt,
  UilSetting,
  UilShoppingCart,
  UilUsersAlt,
  UilBell,
  UilMoneyWithdraw,
  UilListUiAlt,
  UilArchive,
  UilEnvelopeStar,
  UilMonitor,
  UilCancel
} from "@iconscout/react-unicons";
import { Menu } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import UilEllipsisV from "@iconscout/react-unicons/icons/uil-ellipsis-v";
import propTypes from "prop-types";
import { NavTitle } from "./Style";
import versions from "../demoData/changelog.json";
import {
  changeDirectionMode,
  changeLayoutMode,
  changeMenuMode,
} from "../redux/themeLayout/actionCreator";

function MenuItems({ toggleCollapsed }) {
  const { t } = useTranslation();

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const { topMenu } = useSelector((state) => {
    return {
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  const dispatch = useDispatch();

  const path = "/admin";

  const pathName = window.location.pathname;
  const pathArray = pathName ? pathName.split(path) : [];
  const mainPath = pathArray.length > 1 ? pathArray[1] : "";
  const mainPathSplit = mainPath.split("/");

  const [openKeys, setOpenKeys] = React.useState(
    !topMenu
      ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
      : []
  );

  const onOpenChange = (keys) => {
    setOpenKeys(
      keys[keys.length - 1] !== "recharts"
        ? [keys.length && keys[keys.length - 1]]
        : keys
    );
  };

  const onClick = (item) => {
    if (item.keyPath.length === 1) setOpenKeys([]);
  };

  const changeLayout = (mode) => {
    dispatch(changeLayoutMode(mode));
  };
  const changeNavbar = (topMode) => {
    const html = document.querySelector("html");
    if (topMode) {
      html.classList.add("ninjadash-topmenu");
    } else {
      html.classList.remove("ninjadash-topmenu");
    }
    dispatch(changeMenuMode(topMode));
  };
  const changeLayoutDirection = (rtlMode) => {
    if (rtlMode) {
      const html = document.querySelector("html");
      html.setAttribute("dir", "rtl");
    } else {
      const html = document.querySelector("html");
      html.setAttribute("dir", "ltr");
    }
    dispatch(changeDirectionMode(rtlMode));
  };

  const darkmodeActivated = () => {
    document.body.classList.add("dark-mode");
  };

  const darkmodeDiactivated = () => {
    document.body.classList.remove("dark-mode");
  };

  const items = [
    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/dashboard`}>
        {t("dashboard")}
      </NavLink>,
      "dashboard",
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/dashboard`}>
          <UilCreateDashboard />
        </NavLink>
      )
    ),
    getItem(t("Users"), "users", !topMenu && <UilUsersAlt />, [
      getItem(
        <NavLink
          onClick={toggleCollapsed}
          to={`${path}/users/registered-landlord`}
        >
          {t("Landlords")}
        </NavLink>,
        "registered-landlord",
        null
      ),
      getItem(
        <NavLink
          onClick={toggleCollapsed}
          to={`${path}/users/registered-taskers`}
        >
          {t("Taskers")}
        </NavLink>,
        "registered-taskers",
        null
      ),
    ]),

    getItem(t("Categories"), "categories", !topMenu && <UilShoppingCart />, [
      getItem(
        <NavLink
          onClick={toggleCollapsed}
          to={`${path}/categories`}
        >
          {t("Categories Listing")}
        </NavLink>,
        "categorieslisting",
        null
      ),
    ]),

    getItem(t("Services"), "service", !topMenu && <UilListUiAlt />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/services`}>
          {t("Service Listings")}
        </NavLink>,
        "services",
        null
      ),
    ]),

    getItem(
      t("Task Monitoring"),
      "task-monitoring",
      !topMenu && <UilMonitor />,
      [
        getItem(
          <NavLink
            onClick={toggleCollapsed}
            to={`${path}/task-monitoring`}
          >
            {t("Task Monitoring Listing")}
          </NavLink>,
          "listing",
          null
        ),
      ]
    ),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/booking-disputes`}>
        {t("Booking Disputes")}
      </NavLink>,
      "booking-disputes",
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/booking-disputes`}>
          <UilArchive />
        </NavLink>
      )
    ),
    
    // getItem(t('Reviews'), 'reviews', !topMenu && <UilEnvelopeStar />, [
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/reviews/pending-reviews`}>
    //       {t('Pending Review')}
    //     </NavLink>,
    //     'pending-reviews',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/reviews/approved-reviews`}>
    //       {t('Approved Review')}
    //     </NavLink>,
    //     'approved-reviews',
    //     null,
    //   ),
    //   getItem(
    //     <NavLink onClick={toggleCollapsed} to={`${path}/reviews/rejected-reviews`}>
    //       {t('Rejected Review')}
    //     </NavLink>,
    //     'rejected-reviews',
    //     null,
    //   ),
    // ]),

    getItem(t("Reviews"), "reviews", !topMenu && <UilEnvelopeStar />, [
      getItem(
        <NavLink
          onClick={toggleCollapsed}
          to={`${path}/reviews/landlord`}
        >
          {t("Landlords")}
        </NavLink>,
        "landlord-reviews",
        null
      ),
      getItem(
        <NavLink
          onClick={toggleCollapsed}
          to={`${path}/reviews/taskers`}
        >
          {t("Taskers")}
        </NavLink>,
        "taskers-reviews",
        null
      ),
    ]),

    getItem(t("Payouts"), "payout", !topMenu && <UilMoneyWithdraw />, [
      getItem(
        <NavLink
          onClick={toggleCollapsed}
          to={`${path}/payout/pending-payouts`}
        >
          {t("Pending")}
        </NavLink>,
        "pending-payouts",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/payout/paid-payouts`}>
          {t("Paid")}
        </NavLink>,
        "paid-payouts",
        null
      ),
      // getItem(
      //   <NavLink onClick={toggleCollapsed} to={`${path}/payout/unpaid-payouts`}>
      //     {t('Unpaid Payouts')}
      //   </NavLink>,
      //   'unpaid-payouts',
      //   null,
      // ),
    ]),

    getItem(
      <NavLink onClick={toggleCollapsed} to={`${path}/notifications`}>
        {t("Notification")}
      </NavLink>,
      "notifications",
      !topMenu && (
        <NavLink className="menuItem-iocn" to={`${path}/notifications`}>
          <UilBell />
        </NavLink>
      )
    ),

    getItem(t("CMS"), "cms", !topMenu && <UilFileShieldAlt />, [
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/banner-management`}>
          {t("Banner Management")}
        </NavLink>,
        "banner-management",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/terms-conditions`}>
          {t("Terms and Conditions")}
        </NavLink>,
        "terms-conditions",
        null
      ),
      // getItem(
      //   <NavLink onClick={toggleCollapsed} to={`${path}/cms/privacyPolicy`}>
      //     {t('Privacy Poli')}
      //   </NavLink>,
      //   'privacy policy',
      //   null,
      // ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/privacy-policy`}>
          {t("Privacy Policy")}
        </NavLink>,
        "privacy-policy",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/faqs/landlords`}>
          {t("FAQs For Landlords")}
        </NavLink>,
        "faqslandlords",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/faqs/taskers`}>
          {t("FAQs For Taskers")}
        </NavLink>,
        "faqstaskers",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/refund-policy`}>
          {t("Refund Policy")}
        </NavLink>,
        "refund-policy",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/about-us`}>
          {t("About Us")}
        </NavLink>,
        "about-us",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/disputes`}>
          {t("Disputes")}
        </NavLink>,
        "disputes",
        null
      ),
      getItem(
        <NavLink onClick={toggleCollapsed} to={`${path}/cms/cancellation`}>
          {t("Cancellation")}
        </NavLink>,
        "cancellation",
        null
      ),
    ]),

    // getItem(
    //   <NavLink onClick={toggleCollapsed} to={`${path}/settings`}>
    //     {t("Settings")}
    //   </NavLink>,
    //   "settings",
    //   !topMenu && (
    //     <NavLink className="menuItem-iocn" to={`${path}/settings`}>
    //       <UilSetting />
    //     </NavLink>
    //   )
    // ),
  

  getItem(t("Settings"), "settings", !topMenu && <UilSetting />, [
    getItem(
      <NavLink
        onClick={toggleCollapsed}
        to={`${path}/settings/site-settings`}
      >
        {t("Site Settings")}
      </NavLink>,
      "",
      null
    ),
    getItem(
      <NavLink
        onClick={toggleCollapsed}
        to={`${path}/settings/commission`}
      >
        {t("Commission Setting")}
      </NavLink>,
      "commission",
      null
    ),

    getItem(
      <NavLink
        onClick={toggleCollapsed}
        to={`${path}/settings/cancellation`}
      >
        {t("Cancellation Setting")}
      </NavLink>,
      "cancellation-setting",
      null
    ),

    getItem(
      <NavLink
        onClick={toggleCollapsed}
        to={`${path}/settings/vat`}
      >
        {t("Vat Setting")}
      </NavLink>,
      "vat",
      null
    ),
  ]),
];

  return (
    <Menu
      onOpenChange={onOpenChange}
      onClick={onClick}
      mode={!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"}
      // // eslint-disable-next-line no-nested-ternary
      defaultSelectedKeys={
        !topMenu
          ? [
              `${
                mainPathSplit.length === 1
                  ? "home"
                  : mainPathSplit.length === 2
                  ? mainPathSplit[1]
                  : mainPathSplit[2]
              }`,
            ]
          : []
      }
      defaultOpenKeys={
        !topMenu
          ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
          : []
      }
      overflowedIndicator={<UilEllipsisV />}
      openKeys={openKeys}
      items={items}
    />
  );
}

MenuItems.propTypes = {
  toggleCollapsed: propTypes.func,
};

export default MenuItems;
